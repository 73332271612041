import Vue from 'vue'
import AppMenuHeader from './AppMenuHeader.vue'

import VueAxios from 'vue-axios';
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css"

Vue.use(VueAxios, axios);

//Main URL API
// Local
// Vue.prototype.$apiUrl = 'http://fateknowa.test/wp-json/wp/v2'
// Serve
Vue.prototype.$apiUrl = 'https://fatek.mpkr.pl/wp-json/wp/v2'
Vue.prototype.$menu = '/menu'
Vue.prototype.$menuTypeOffer = 'Produkty'

Vue.config.productionTip = false

new Vue({
  render: h => h(AppMenuHeader),
}).$mount('#vueModuleMenu')